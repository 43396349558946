<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>巡查管理</el-breadcrumb-item>
        <el-breadcrumb-item>自查自纠列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button
          v-if="isshow == 1"
          class="back_btn"
          size="medium"
          plain
          @click="$router.back()"
          >返回</el-button
        >
      </div>
    </el-header>
    <el-main class="p15">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="企业名称、经营者"
              v-model="page.company_name"
              clearable
              @clear="onSubmit"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
          <el-form-item v-if="isshow != 1">
            <el-button type="primary" @click="Add()">新增自查自纠 </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input placeholder="企业名称、经营者" v-model="page.company_name" clearable class="input-with-select"
                @clear="onSubmit">

              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl">
            <el-form-item size="medium">
              <el-button type="primary" @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
              <el-button v-if="isshow!=1" type="primary" @click="Add()" class="general_bgc general_border cfff">新增自查自纠
              </el-button>
            </el-form-item>
          </el-col>

        </el-form>
      </el-row> -->
      <div class="box list">
        <!-- <div class="tl list_tab ">
          <div v-for="(v,k) in list_btn4" :key="k" class="pr disib mr10">
          <el-button  :class="activeClass ==k?'active':''" size="medium" @click="IsActive(k,v.id)">{{v.title}}<span v-if="v.id=='2'" class="list_tag pa disib bgcred cfff">{{count}}</span></el-button>  
          </div>
         </div> -->
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column
            v-if="activeClass == 1"
            type="selection"
            label="选择"
            width="60"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="company_name"
            min-width="150"
            :show-overflow-tooltip="true"
            label="企业名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="add_time"
            min-width="150"
            :show-overflow-tooltip="true"
            label="自查自纠时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="user_name_add"
            min-width="150"
            :show-overflow-tooltip="true"
            label="操作人"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            class-name="aa"
            min-width="150"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="godetail(scope.row.check_self_id)"
                >详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local, aa, session } from "../../../util/util";
import Globle from "@/components/Globle";
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: {
        pager_offset: "0",
        pager_openset: "10",
        company_id: "",
        company_name: "",
        is_check_self: "",
      },
      page1: {
        company_code: "",
        checkself_code_list: [],
      },
      selected: "",
      options4: "",
      options2: "",
      activeClass: 0,
      statusOptions: [
        { label: "全部", value: "" },
        { label: "已自查自纠", value: "1" },
        { label: "未自查自纠", value: "2" },
      ],
      currentPage: 1,
      total: 0,
      count: "",
      tableData: [],
      multipleSelection: [],
      allSelect: false,
      isshow: "",
    };
  },
  computed: {
    ...mapState({
      checkSelfPage: (state) => state.checkSelf.checkSelfPage,
    }),
  },
  created() {
    var that = this;
    that.isshow = session.get("params1").name
      ? String(session.get("params1").name)
      : "";
    that.page = Object.assign({}, that.checkSelfPage);
    if (that.isshow == 1) {
      this.page.company_id = session.get("params1").id
        ? String(session.get("params1").id)
        : "";
    } else {
      this.company_id = local.get("company_id")
        ? String(local.get("company_id"))
        : "";
    }

    that.currentPage = that.page.pager_offset / 10 + 1;
    that.activeClass = String(that.page.ischeckself) - 1;
    that.init(that.page);
  },
  methods: {
    init(params) {
      var that = this;
      axios
        .get("/pc/company-cs/company-list", params)
        .then((v) => {
          that.tableData = v.data.company_cs_list;
          that.total = v.pager_count;
        })
        .catch(() => {});
    },

    // 搜索
    onSubmit() {
      var that = this;
      that.currentPage = 1;
      that.page.pager_offset = "0";
      that.$store.dispatch("checkSelf/setNewPage", that.page);
      that.init(that.page);
    },
    // 分页
    currentChange(page) {
      var that = this;
      that.currentPage = page;
      that.page.pager_offset = String((page - 1) * 10);
      that.$store.dispatch("checkSelf/setNewPage", that.page);
      that.init(that.page);
    },
    Add() {
      session.set("params", { id: "", name: "" });
      this.$router.push({ name: "CheckSelfAdd" });
    },

    godetail(index) {
      session.set("params", { id: index, name: "" });
      this.$router.push({
        name: "CheckSelfDetail",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }
  /deep/ .el-checkbox__label {
    display: none !important;
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
  /deep/ .el-table .disabledCheck .cell .el-checkbox__inner {
    display: none !important;
  }

  /deep/ .el-table .disabledCheck .cell::before {
    content: "选择";
    text-align: center;
    line-height: 37px;
  }
}
</style>
